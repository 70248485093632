import React, { useState } from "react";
import BlogWrapper from "~components/blog-wrapper";
import { Section, SubSection } from "~components/text-helpers";
import { EmailSubscription } from "~components/email-subscribe";

import { Steps, Tooltip } from "antd";
const { Step } = Steps;

import { CopyFilled } from "@ant-design/icons";

import color from "~styles/color";
import { css } from "@emotion/react";
import Latex from "react-latex";
import { Emoji } from "emoji-mart";

import { Code, LeaderboardSubmission, IL } from "~components/documentation";
import { GitHubButton } from "./robothor/cvpr-2021-challenge";

import PRIOR_WHEEL from "~icons/prior-wheel.svg";
import AI2_LOGO from "~icons/ai2-logo.svg";
import LEADERBOARD_1_PHASE from "~icons/1-phase-leaderboard-icon.svg";
import LEADERBOARD_2_PHASE from "~icons/2-phase-leaderboard-icon.svg";

import { graphql } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { Organizer } from "./robothor/challenge";

import { BibTex } from "~components/bibtex";

import coverGIF from "~media/task_example.gif";

export default function Challenge({ data, location }) {
  return (
    <BlogWrapper
      title="FurnMove Challenge 2022"
      subtitle="Held in conjunction with the CVPR 2022 Embodied AI Workshop"
      keywords="FurnMove, CVPR, 2022, Challenge"
      description="The 2022 FurnMove Challenge held in conjunction with the CVPR 2022 Embodied AI Workshop."
    >
      <img
        src={coverGIF}
        alt="Example of agents solving the FurnMove Task."
        css={css`
          width: calc(100% + 50px);
          margin-left: -25px;
        `}
      />
      <p
        css={css`
          margin-top: 10px;
        `}
      >
        Welcome to the 2022 AI2-THOR Furniture Moving (FurnMove) Challenge
        hosted at the CVPR 2022 Embodied AI Workshop. The goal of this challenge
        is to develop collaborative embodied agents. Particularly, two agents
        need to work together to move a piece of furniture through a living room
        to a goal. We are interested in the more realistic decentralized setting
        enabled via low-bandwidth communication.
      </p>
      <p>
        We'll be updating more details on March 1st, 2022. So, stay tuned for
        updates!
      </p>
      <Section sectionTitle="Task" emoji="memo">
        <Img fluid={data.taskTeaser.childImageSharp.fluid} />
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          Given only their egocentric visual observations, agents jointly hold a
          lifted piece of furniture in a living room scene and must collaborate
          to move it to a visually distinct goal location. As a piece of
          furniture cannot be moved without both agents agreeing on the
          direction, agents must explicitly coordinate at every timestep.
        </p>
        <p>
          In FurnMove, each agent at every timestep receives an egocentric
          observation (a <Latex>$3\times 84 \times 84$</Latex> RGB image) from
          AI2-THOR. In addition, agents are allowed to communicate with other
          agents at each timestep via a low bandwidth communication channel.
          Based on their local observation and communication, each agent must
          take an action from the set <Latex>$A$</Latex>.
        </p>
      </Section>
      <Section sectionTitle="Allowed Observations" emoji="frame_with_picture">
        <p>
          At test time each agent must take only a egocentric observation (a{" "}
          <Latex>$3\times 84 \times 84$</Latex> RGB image) from AI2-THOR.
        </p>
        <p>
          Do not exploit the metadata in test-scenes: You cannot use additional
          depth, mask, metadata info etc. from the simulator on test scenes.
          However, during training you are free to use additional info for
          things like auxiliary losses.
        </p>
        <p>
          If you use additional sensory information from AI2-THOR as input
          (e.g., depth, segmentation masks, class masks, panoramic images)
          during test-time, your entry will not be considered. For official
          consideration to the CVPR 2022 challenge, agents should just use RGB
          input.
        </p>
      </Section>
      <Section sectionTitle="Allowed Actions" emoji="running">
        <p>
          Each agent can take has an action space defined by{" "}
          <Latex>{`$A = A^{NAV} ∪ A^{MWO} ∪ A^{MO} ∪ A^{RO}$`}</Latex>, where:
        </p>
        <ul>
          <li>
            <Latex>
              {`$A^{NAV} = \\lbrace \\text{MoveAhead}, \\text{RotateLeft}, \\text{RotateRight}, \\text{Pass}\\rbrace$ used to independently move each agent.`}
            </Latex>
          </li>
          <li>
            <Latex>
              {`$A^{MWO} = \\lbrace \\text{MoveWithObject}X \\mid X \\in \\lbrace \\text{Ahead}, \\text{Right}, \\text{Left}, \\text{Back} \\rbrace\\rbrace$ to move the lifted object and the agents simultaneously in the same direction.`}
            </Latex>
          </li>
          <li>
            <Latex>
              {`$A^{MO} = \\lbrace \\text{MoveObject}X \\mid X \\in \\lbrace \\text{Ahead}, \\text{Right}, \\text{Left}, \\text{Back} \\rbrace\\rbrace$ used to move the lifted object while the agents stay in place.`}
            </Latex>
          </li>
          <li>
            <Latex>
              {`$A^{RO} = \\lbrace \\text{RotateObjectRight} \\rbrace$ to rotate the lifted object clockwise.`}
            </Latex>
          </li>
        </ul>
        <p>
          So the two agents, together, have joint action space{" "}
          <Latex>$13 \times 13 = 169$</Latex> actions. The coordination of this
          action space is defined by the following coordination matrix:
        </p>
        <Img fluid={data.coordinationMatrix.childImageSharp.fluid} />
        <p>
          <Latex>
            We assume that all movement actions for agents and the lifted object
            result in a displacement of $0.25$ meters and all rotation actions
            result in a rotation of $90$ degrees counter-clockwise when viewing
            the agents from above.
          </Latex>
        </p>
      </Section>
      <Section sectionTitle="Timeline" emoji="hourglass">
        <Steps progressDot current={0} direction="vertical">
          <Step
            title="FurnMove Challenge Announced"
            description="Feb 14, 2022"
          />
          <Step
            title="Challenge Code and Data Release"
            description="March 1, 2022"
          />
          <Step title="Submissions Close" description="June 3, 2022" />
          <Step title="Winner Announcement" description="Jun 19, 2022" />
        </Steps>
      </Section>
      <Section sectionTitle="Participation" emoji="rocket">
        To participate in the challenge, we'll put up a FurnMove Challenge
        repository on March 1st, 2022. Meanwhile, you can get started with the
        ECCV 2020 repository available on GitHub at:
        <GitHubButton
          url="//github.com/allenai/cordial-sync"
          repoName="/allenai/cordial-sync"
        />
        <p>
          Winners of the challenge will have the opportunity to present their
          work at the{" "}
          <a href="//embodied-ai.org">CVPR 2022 Embodied AI Workshop</a>.
        </p>
      </Section>
      <Section sectionTitle="Submissions" emoji="mountain_railway">
        <p>More details will be updated on March 1st, 2022.</p>
      </Section>
      <Section sectionTitle="Citation" emoji="pencil2">
        <p>
          To cite this work, please cite our papers on multi-agent furniture
          moving:
        </p>
        <BibTex
          bibtexText={String.raw`@InProceedings{CordialSync,
  author = {Jain, Unnat and Weihs, Luca and Kolve, Eric and Farhadi, Ali and Lazebnik, Svetlana and Kembhavi, Aniruddha and Schwing, Alexander G.},
  title = {A Cordial Sync: Going Beyond Marginal Policies For Multi-Agent Embodied Tasks},
  booktitle = {ECCV},
  year = {2020},
  note = {first two authors contributed equally},
}

@InProceedings{TwoBody,
  author = {Jain, Unnat and Weihs, Luca and Kolve, Eric and Rastegari, Mohammad and Lazebnik, Svetlana and Farhadi, Ali and Schwing, Alexander G. and Kembhavi, Aniruddha},
  title = {Two Body Problem: Collaborative Visual Task Completion},
  booktitle = {CVPR},
  year = {2019},
  note = {first two authors contributed equally},
}`}
        />
      </Section>
      <Section sectionTitle="Organizers" emoji="wave">
        The FurnMove challenge organizers are listed below:
        <div
          css={css`
            /* background-image: url(${PRIOR_WHEEL}); */
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            text-align: center;
          `}
        >
          {[
            "Unnat Jain",
            "Ani Kembhavi",
            "Alexander Schwing",
            "Luca Weihs",
          ].map((name: string) => (
            <Organizer
              name={name}
              fixedImg={
                data[name.substring(0, name.indexOf(" ")).toLowerCase()]
                  .childImageSharp.fluid
              }
            />
          ))}
        </div>
      </Section>
    </BlogWrapper>
  );
}

export const query = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  query {
    alexander: file(relativePath: { eq: "headshots/alexander.png" }) {
      ...FluidImage
    }
    ani: file(relativePath: { eq: "headshots/ani.jpg" }) {
      ...FluidImage
    }
    luca: file(relativePath: { eq: "headshots/luca.jpg" }) {
      ...FluidImage
    }
    unnat: file(relativePath: { eq: "headshots/unnat.jpg" }) {
      ...FluidImage
    }

    taskTeaser: file(relativePath: { eq: "task_teaser.png" }) {
      ...FluidImage
    }
    coordinationMatrix: file(relativePath: { eq: "coordination_matrix.png" }) {
      ...FluidImage
    }
  }
`;
